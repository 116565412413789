<template>
	<div>
		<contentHeader title="Kartu Stok" />

		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
          						<h3 class="card-title col-6">Kartu Stok</h3>
          					</div>
			          		<div class="card-body">
			          			<form @submit.prevent="prosesKartuStok">
			          				<div class="row px-lg-2">
			          					<div class="col-8">
			          						<div class="form-group">
					                    <label>Nama Obat</label>
					                  </div>
			          					</div>
			          					<div class="col-2">
					                    <label>Stok Sistem</label>
			          					</div>
			          					<div class="col-2">
					                    <label>Stok Real</label>
			          					</div>
			          				</div>

			          				<div class="row px-lg-2" v-for="row in obat" :key="row.id">
			          					<div class="col-8">
			          						<div class="form-group">
					                    <input type="text" class="form-control" v-model="row.nama" disabled="">
					                  </div>
			          					</div>
			          					<div class="col-2">
					                    <input type="number" class="form-control" disabled="" v-model="row.stok_akhir">
			          					</div>
			          					<div class="col-2">
					                    <input type="number" class="form-control" v-model="row.stok_real">
			          					</div>
			          				</div>
			          			<div class="row ml-2 mt-2">	
				          			<button type="submit" class="btn btn-primary d-flex align-items-center">
									  <div>
									  	Update Stok
									  </div>	
									  <div>
					                    <svg v-if="loading" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style=" background: none; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
					                      <g>
					                        <path d="M50 15A35 35 0 1 0 74.74873734152916 25.251262658470843" fill="none" stroke="#ffffff" stroke-width="12"></path>
					                        <path d="M49 3L49 27L61 15L49 3" fill="#ffffff"></path>
					                        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
					                      </g>

					                    </svg>
					                  </div>
									</button>
			          			</div>
			          			</form>
			          		</div>
			          	</div>
			          </div>
			      </div>
			</div>
		</div>
	</div>
</template>

<script>
	import ContentHeader from '@/components/ContentHeader'

	import { computed, ref, onMounted } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import Swal from 'sweetalert2'

	export default{
		components: {
			ContentHeader: ContentHeader
		},
		setup () {
			const loading = ref(false)
			const user = computed(() => store.getters['auth/user'])
			const obat = ref([])
			const length = ref('')

			const getObat = async () => {
				let { data } = await axios.get(`api/obat/get/${user.value.cabang_id}`)

				for (var i = 0; i < data.length; i++) {
					obat.value.push({
						'id' : data[i].id,
						'nama' : data[i].nama,
						'stok_akhir' : data[i].stok_akhir,
						'stok_real' : ''
					})
				}

				length.value = data.length

			}

			const prosesKartuStok = () => {
				loading.value = true
				axios.post(`api/obat/kartuStok/${length.value}`, obat.value)
				.then((response) => {
					Swal.fire({
					  title: 'Berhasil!',
					  text: 'Berhasil Menanbahkan Data',
					  icon: 'success',
					  showConfirmButton: false,
					  timer: 1500
					})	
					loading.value = false
					console.log(response)
					obat.value = []
					getObat()
				})
				.catch((error) => {
					Swal.fire({
					  title: 'Gagal!',
					  text: 'Gagal Menanbahkan Data',
					  icon: 'error',
					  confirmButtonText: 'Ok'
					})	
					loading.value = false
					console.log(error)
				})
			}

			onMounted(() => {
				getObat()
			})

			return { obat, prosesKartuStok, loading }
		}
	}
</script>